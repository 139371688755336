<template>
  <div class="home">
    <img
      alt="Milady logo"
      class="ma-2"
      src="../../public/img/milady_logo.svg"
    />
    <v-container>
      <div class="text-center">
        <Menu></Menu>
      </div>
    </v-container>
    <v-container>
      <h1 class="text-center mt-5 mb-5">MiLady App Dashboard</h1>

      <v-row class="align-content-center">
        <v-col>
          <h5 class="fw-normal">Total Registered Users</h5>
          <p class="purple--text text-h2">{{ totalCount.total_users }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <PieChart
            chart-id="totalUsersPie"
            :key="componentKey3"
            :chartData="totalUsersPieData"
          />
          <div class="d-flex align-end flex-column">
            <v-btn
              x-small
              color="secondary"
              dark
              @click="downloadasImage('totalUsersPie', 'Total Users')"
              >Download
            </v-btn>
          </div>
        </v-col>
        <v-col>
          <PieChart
            chart-id="totalGenderPie"
            :key="componentKey4"
            :chartData="totalGenderPieData"
          />
          <div class="d-flex align-end flex-column">
            <v-btn
              x-small
              color="secondary"
              dark
              @click="downloadasImage('totalGenderPie', 'Total Gender')"
              >Download
            </v-btn>
          </div>
        </v-col>
        <v-col>
          <PieChart
            :key="componentKey5"
            chart-id="totalMaritalStatusPie"
            :chartData="totalMaritalStatusPieData"
          />
          <div class="d-flex align-end flex-column">
            <v-btn
              x-small
              color="secondary"
              dark
              @click="
                downloadasImage('totalMaritalStatusPie', 'Total Marital Status')
              "
              >Download
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <!-- Non Provider Chart -->
      <h2 class="mt-5 mb-5">Monthly Count of Non Provider</h2>
      <v-row class="mt-5">
        <v-col>
          <v-select
            :items="genderTypes"
            item-text="name"
            item-value="value"
            label="Gender"
            v-model="n_gender"
            multiple
            @change="getNonProviderData()"
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="maritalStatusTypes"
            item-text="name"
            item-value="value"
            label="Marital Status"
            v-model="n_marital_status"
            required
            @change="getNonProviderData()"
            multiple
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="ageList"
            item-text="name"
            item-value="value"
            label="Age"
            v-model="n_age"
            multiple
            @change="getNonProviderData()"
            required
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="stateRegions"
            item-text="name"
            item-value="value"
            label="State Region"
            v-model="n_state_region"
            required
            @change="getNonProviderData()"
            multiple
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="pYears"
            item-text="name"
            item-value="value"
            label="Year"
            v-model="nYear"
            required
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <div class="d-flex align-end flex-column">
        <v-row>
        <v-btn
          x-small
          color="third"
          dark
          @click="resetNonProviderForm()"
          >Reset
        </v-btn>
        &nbsp;
        <!-- <v-btn
          x-small
          color="third"
          dark
          @click="getNonProviderData()"
          >Submit
        </v-btn> -->
      </v-row>
      </div>
      <div class="mt-5"></div>
      <div style="height: 20px">
        <v-progress-linear
          indeterminate
          v-show="nLoadingData"
          color="pink"
        ></v-progress-linear>
      </div>
      <span>Total Non Provider: {{ totalNonProvider }}</span>
      <BarChart
        chart-id="totalNonProviderChart"
        :key="componentKey"
        :chartData="chartData"
        v-if="nbarChartLoaded"
      />
      <div class="d-flex align-end flex-column">
        <v-btn
          v-if="nbarChartLoaded"
          x-small
          color="secondary"
          dark
          @click="
            downloadasImage('totalNonProviderChart', 'Total Non Provider')
          "
          >Download
        </v-btn>
      </div>
      <!-- Provider Chart -->
      <h2 class="mt-5 mb-5">Monthly Count of Provider</h2>

      <v-row class="mt-5">
        <v-col>
          <v-select
            :items="genderTypes"
            item-text="name"
            item-value="value"
            label="Gender"
            v-model="gender"
            required
            @change="getProviderData()"
            multiple
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="maritalStatusTypes"
            item-text="name"
            item-value="value"
            label="Marital Status"
            v-model="marital_status"
            required
            @change="getProviderData()"
            multiple
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="providerTypes"
            item-text="name"
            item-value="value"
            label="Provider Types"
            v-model="provider_type"
            required
            @change="getProviderData()"
            multiple
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="stateRegions"
            item-text="name"
            item-value="value"
            label="State Region"
            v-model="state_region"
            required
            @change="getProviderData()"
            multiple
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            :items="pYears"
            item-text="name"
            item-value="value"
            label="Year"
            v-model="pYear"
            required
            @change="getProviderData()"
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <div class="d-flex align-end flex-column">
        <v-row>
        <v-btn
          x-small
          color="third"
          dark
          @click="resetProviderForm()"
          >Reset
        </v-btn>
        &nbsp;
        <!-- <v-btn
          x-small
          color="third"
          dark
          @click="getProviderData()"
          >Submit
        </v-btn> -->
      </v-row>
      </div>
      <div class="mt-5"></div>
      <div style="height: 20px">
        <v-progress-linear
          indeterminate
          v-show="pLoadingData"
          color="purple"
        ></v-progress-linear>
      </div>
      <span>Total Provider: {{ totalProvider }}</span>
      <BarChart
        chart-id="pChart"
        :key="componentKey2"
        :chartData="pchartData"
        v-if="pbarChartLoaded"
      />
      <div class="d-flex align-end flex-column">
        <v-btn
          v-if="pbarChartLoaded"
          x-small
          color="secondary"
          dark
          @click="downloadasImage('pChart', 'Total Provider')"
          >Download
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import {
  FETCH_TOTALUSERCOUNT,
  FETCH_TOTALUSERCOUNTFISCAL,
  FETCH_TOTAL_USER_COUNTRY_COUNT_PUBLIC_REPORT,
} from "../store/actions.type";
import BarChart from "@/components/MiladyBarChart.vue";
import PieChart from "@/components/PieChart.vue";
import Menu from "@/components/Menu.vue";
import {userTypes,providerTypes,pYears, genderTypes,ageList,maritalStatusTypes,stateRegions}  from "../common/ref_data";

export default {
  name: "Home",
  components: {
    PieChart,
    BarChart,
    Menu,
  },
  data() {
    return {
      nLoadingData: false,
      pLoadingData: false,
      componentKey: 0,
      componentKey2: 0,
      componentKey3: 0,
      componentKey4: 0,
      componentKey5: 0,
      nbarChartLoaded: false,
      pbarChartLoaded: false,
      pMonth: null,
      pYear: null,
      nMonth: null,
      nYear: null,
      chartData: {},
      pchartData: {},
      totalProvider: 0,
      totalNonProvider: 0,
      userTypes: userTypes,
      ageList: ageList,
      genderTypes: genderTypes,
      maritalStatusTypes: maritalStatusTypes,
      stateRegions: stateRegions,
      providerTypes: providerTypes,
      pYears : pYears,
      providerTypeList: [],
      n_age: null,
      gender: null,
      marital_status: null,
      state_region: null,
      provider_type: null,
      n_gender: null,
      n_marital_status: null,
      n_state_region: null,
      totalCount: {
        total_users: 0,
        total_normal_users: 0,
        total_provider_users: 0,
        gender_male: 0,
        gender_female: 0,
        gender_other: 0,
        single: 0,
        married: 0,
      },
      totalUsersPieData: {},
      totalCountryUsersPieData: {},
      totalGenderPieData: {},
      totalMaritalStatusPieData: {},
      defaultData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            label: "No of User",
            color: "#ffffff",
            backgroundColor: "#FF2E74",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],
      },
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      ndatasets: [
        {
          barThickness: 50,
          maxBarThickness: 60,
          label: "No of User",
          color: "#ffffff",
          backgroundColor: "#FF2E74",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      pMonths: [
        { name: "All Months", value: null },
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
      ],
      pdatasets: [
        {
          barThickness: 50,
          maxBarThickness: 60,
          label: "No of User",
          color: "#ffffff",
          backgroundColor: "#CB0F92",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      pdefaultData: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      },
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getTotalUsercount();
    this.getNonProviderData();
    this.getProviderData();
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    getTotalUsercount() {
      this.$store
        .dispatch(FETCH_TOTALUSERCOUNT)
        .then((data) => {
          this.totalCount = data.totalCount[0];
          this.getTotalUserPieChart(
            this.totalCount.total_normal_users,
            this.totalCount.total_provider_users
          );
          this.getTotalGenderPieChart();
          this.getTotalMaritalStatusPieChart();
        })
        .catch((response) => {
          this.errors = response;
        });
    },
    getTotalCountryUserCount() {
      this.$store
        .dispatch(FETCH_TOTAL_USER_COUNTRY_COUNT_PUBLIC_REPORT)
        .then((data) => {
          this.totalCount = data.totalCount[0];
          this.getTotalCountryUserPieChart(
            this.totalCount.total_normal_users,
            this.totalCount.total_provider_users
          );
          this.getTotalGenderPieChart();
          this.getTotalMaritalStatusPieChart();
        })
        .catch((response) => {
          this.errors = response;
        });
    },
    getTotalUserPieChart(non, p) {
      if (parseInt(non) > 0 && parseInt(p) > 0) {
        var defaultData = {
          labels: [`Non-Provider (${non})`, `Provider (${p})`],
          datasets: [
            {
              barThickness: 50,
              maxBarThickness: 60,
              label: "",
              backgroundColor: ["#EF5DA8", "#7e13a1"],
              data: [non, p],
            },
          ],
        };

        this.totalUsersPieData = defaultData;
        this.componentKey3 += 1;
      }
    },
    getTotalCountryUserPieChart(non, p) {
      if (parseInt(non) > 0 && parseInt(p) > 0) {
        var defaultData = {
          labels: [`Country (${non})`, `Count (${p})`],
          datasets: [
            {
              barThickness: 50,
              maxBarThickness: 60,
              label: "",
              backgroundColor: ["#EF5DA8", "#7e13a1"],
              data: [non, p],
            },
          ],
        };

        this.totalUsersPieData = defaultData;
        this.componentKey3 += 1;
      }
    },
    getTotalGenderPieChart() {
      var defaultData = {
        labels: [
          `Female (${this.totalCount.gender_female})`,
          `Male (${this.totalCount.gender_male})`,
          `Others (${this.totalCount.gender_other})`,
        ],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            label: "",
            backgroundColor: ["#EF5DA8", "#7e13a1", "#798F84"],
            data: [
              this.totalCount.gender_female,
              this.totalCount.gender_male,
              this.totalCount.gender_other,
            ],
          },
        ],
      };

      this.totalGenderPieData = defaultData;
      this.componentKey4 += 1;
    },
    getTotalMaritalStatusPieChart() {
      var defaultData = {
        labels: [
          `Single (${this.totalCount.single})`,
          `Married (${this.totalCount.married})`,
        ],
        datasets: [
          {
            barThickness: 50,
            maxBarThickness: 60,
            label: "",
            backgroundColor: ["#EF5DA8", "#7e13a1"],
            data: [this.totalCount.single, this.totalCount.married],
          },
        ],
      };

      this.totalMaritalStatusPieData = defaultData;
      this.componentKey5 += 1;
    },
    resetNonProviderForm(){
      this.totalNonProvider = 0;
      this.nLoadingData = false;
      this.n_age = null;
      this.n_state_region= null;
      this.n_gender = null;
      this.n_marital_status = null;
      this.nYear = null;
      this.nMonth = null;
      this.getNonProviderData();
    },
    resetProviderForm(){
      this.totalNonProvider = 0;
      this.LoadingData = false;
      this.age = null;
      this.state_region= null;
      this.provider_type = null;
      this.gender = null;
      this.marital_status = null;
      this.Year = null;
      this.Month = null;
      this.getProviderData();
    },
    getNonProviderData() {
      this.totalNonProvider = 0;
      this.nLoadingData = true;
      var payload = {
        user_type: "N",
        age: this.n_age,
        state_region: this.n_state_region,
        gender: this.n_gender,
        marital_status: this.n_marital_status,
        year: this.nYear,
        month: this.nMonth,
      };
      this.$store
        .dispatch(FETCH_TOTALUSERCOUNTFISCAL, payload)
        .then((data) => {
          this.defaultData.labels = [
            "September",
            "October",
            "November",
            "December",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
          ];
          this.defaultData.datasets = [
            {
              barThickness: 50,
              maxBarThickness: 60,
              label: "No of User",
              color: "#ffffff",
              backgroundColor: "#FF2E74",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ];
          if (data != null) {
            this.nLoadingData = false;
            // this.defaultData.datasets[0].data = [];
            for (var i = 0; i < data.length; i++) {
              var index = this.defaultData.labels.indexOf(
                this.getMonth(data[i].month)
              );
              this.defaultData.datasets[0].data[index] = data[i].totaluser;
              this.defaultData.labels[index] =
                data[i].year + " - " + this.getMonth(data[i].month);
              this.totalNonProvider += parseInt(data[i].totaluser);
            }
            this.chartData = this.defaultData;
            this.nbarChartLoaded = true;
          } else {
            this.chartData = {};
            this.nbarChartLoaded = false;
          }
          this.componentKey += 1;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getMonth(month) {
      var monthName = "";
      switch (month) {
        case "1":
          monthName = "January";
          break;
        case "2":
          monthName = "February";
          break;
        case "3":
          monthName = "March";
          break;
        case "4":
          monthName = "April";
          break;
        case "5":
          monthName = "May";
          break;
        case "6":
          monthName = "June";
          break;
        case "7":
          monthName = "July";
          break;
        case "8":
          monthName = "August";
          break;
        case "9":
          monthName = "September";
          break;
        case "10":
          monthName = "October";
          break;
        case "11":
          monthName = "November";
          break;
        case "12":
          monthName = "December";
          break;
      }
      return monthName;
    },
    getProviderData() {
      this.totalProvider = 0;
      this.pLoadingData = true;
      var payload = {
        user_type: "P",
        provider_type: this.provider_type,
        state_region: this.state_region,
        gender: this.gender,
        marital_status: this.marital_status,
        year: this.pYear,
        month: this.pMonth,
      };
      this.$store
        .dispatch(FETCH_TOTALUSERCOUNTFISCAL, payload)
        .then((data) => {
          this.pdefaultData.labels = [
            "September",
            "October",
            "November",
            "December",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
          ];
          this.pdefaultData.datasets = this.pdatasets;
          this.pdefaultData.datasets = [
            {
              barThickness: 50,
              maxBarThickness: 60,
              label: "No of User",
              color: "#ffffff",
              backgroundColor: "#CB0F92",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ];
          if (data != null) {
            this.pLoadingData = false;
            // this.defaultData.datasets[0].data = [];
            for (var i = 0; i < data.length; i++) {
              var index = this.pdefaultData.labels.indexOf(
                this.getMonth(data[i].month)
              );
              this.pdefaultData.datasets[0].data[index] = data[i].totaluser;
              this.pdefaultData.labels[index] =
                data[i].year + " - " + this.getMonth(data[i].month);
              this.totalProvider += parseInt(data[i].totaluser);
            }
            this.pchartData = this.pdefaultData;

            this.pbarChartLoaded = true;
          } else {
            this.pchartData = {};
            this.pbarChartLoaded = false;
          }
          this.componentKey2 += 1;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    downloadasImage(chart, chartTitle) {
      var canvas = document.getElementById(chart);
      const link = document.createElement("a");
      link.download = `${chartTitle}.png`;
      link.href = canvas.toDataURL("image/png", 1);
      link.click();
    },
  },
};
</script>
